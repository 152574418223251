@import 'src/assets/styles/colors';
@import 'src/assets/styles/ui';

.container {
    position: relative;

    .volume__wrapper {
        position: absolute;
        top: -55px;
        right: -18px;

        display: grid;
        justify-content: center;
        align-items: center;
        grid-template-columns: 1fr;

        width: 80px;
        height: 24px;
        padding: 0 10px;

        background-color: $white;
        opacity: 0;

        border-radius: 99px;
        box-shadow: 0 0 16px -4px rgba(16, 24, 40, 0.06), 0px 4px 6px -2px rgba(16, 24, 40, 0.05);

        transform: rotate(270deg);

        z-index: 0;

        transition: z-index 0.1s ease 1s, opacity 0.5s ease 0.5s;

        &:hover {
            opacity: 1;

            z-index: 2;

            transition: z-index 0.1s ease 1s, opacity 0.5s ease;
        }

        .volume {
            @extend %player__progressbar;
        }

        .progress {
            position: absolute;
            left: 10px;

            height: 12px;

            border-radius: 99px;

            background-color: $primary-700;

            pointer-events: none;

            cursor: pointer;
        }
    }

    .icon__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 44px;
        height: 44px;

        border-radius: 100%;

        background-color: $white;

        transition: all 0.7s ease;

        cursor: pointer;

        &:hover {
            background-color: $primary-100;

            transition: all 0.7s ease;

            svg {
                path {
                    stroke: $primary-800;
                    transition: all 0.7s ease;
                }
            }
        }
    }

    &:hover {
        .volume__wrapper {
            opacity: 1;

            z-index: 2;

            transition: opacity 0.5s ease;
        }
    }
}
