$fontFamily: Montserrat, sans-serif;

@mixin font-semibold($fs, $lh) {
    font: 600 $fs $fontFamily;
    line-height: $lh;
}

@mixin font-medium($fs, $lh) {
    font: 500 $fs $fontFamily;
    line-height: $lh;
}

@mixin font-regular($fs, $lh) {
    font: 400 $fs $fontFamily;
    line-height: $lh;
}
