@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';

.container {
    display: flex;
    flex-direction: column;

    width: 100%;
    gap: 12px;

    .verification__buttons {
        display: flex;
        flex-direction: row;
        justify-content: center;

        width: 100%;
        gap: 16px;
    }

    .error__text {
        color: $error-500;
        text-align: center;

        @include font-regular(14px, 18px);
    }
}
