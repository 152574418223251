@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/breakpoints';
@import 'src/assets/styles/ui';

.container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    gap: 12px;

    .player__wrapper {
        position: relative;

        width: 100%;
        height: 100%;

        .player {
            display: grid;

            width: 100% !important;
            height: calc(100vh - 328px) !important;

            background-color: $black;

            border-radius: 24px;
            overflow: hidden;

            video {
                height: calc(100vh - 328px) !important;
            }
        }
    }

    .controllers__outer {
        display: flex;
        justify-content: space-between;

        width: 100%;
        gap: 12px;
    }

    .controllers__paused {
        opacity: 1;
    }
}
