@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/breakpoints';
@import 'src/assets/styles/ui';

.container__selected,
.container__unselected {
    @extend %list__item_container;

    .logo {
        display: flex;
        justify-content: center;
        align-items: center;

        min-width: 48px;
        width: 48px;
        height: 48px;
        margin-right: 6px;
        border-radius: 100px;

        background-color: $yellow-200;

        .logo__company {
            width: 100%;
            height: 100%;
            border-radius: 100px;
        }
    }

    .typography {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        gap: 6px;

        .title {
            color: $gray-800;

            @include font-semibold(16px, 18px);
        }

        .company {
            color: $gray-800;

            @include font-regular(14px, 18px);
        }

        .email {
            @extend %email__typography;
        }
    }
}

.container__selected {
    background-color: $yellow-100;

    box-shadow: 0 0 0 4px #f6ebbccc;
    border: 1px solid $yellow-500;
}
