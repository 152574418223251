@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/breakpoints';

.wrapper__desktop {
    display: flex;
    justify-content: center;
    align-items: flex-start;

    width: 100%;
    padding: 24px;

    @media all and (max-width: $breakpoint-sm) {
        display: none;
    }
}

.wrapper__mobile {
    display: none;

    @media all and (max-width: $breakpoint-sm) {
        position: fixed;
        top: 0;
        left: 0;

        display: flex;
        justify-content: center;

        width: 100%;
        height: 100%;
        max-height: 100vh;
        padding-top: 44px;

        backdrop-filter: blur(12px);
    }

    .modal__container {
        display: none;
        background-color: $white;

        width: calc(100% - 88px);
        height: fit-content;
        padding: 19px 16px;

        border-radius: 24px;

        @media all and (max-width: $breakpoint-sm) {
            display: flex;
            flex-direction: column;

            gap: 18px;
        }

        @media all and (max-width: $breakpoint-xs) {
            width: 100%;
        }

        .modal__header {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            p {
                color: $gray-800;

                @include font-semibold(16px, 18px);
            }

            button {
                display: flex;
                justify-content: center;
                align-items: center;

                width: 24px;
                height: 24px;

                border-radius: 100%;

                svg {
                    width: 12px;
                    height: 12px;

                    path {
                        stroke: $gray-500;
                    }
                }
            }
        }
    }
}
