@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/ui';

.container {
    display: grid;
    grid-template-columns: 45px 1fr auto 45px;
    align-content: center;

    width: 100%;
    gap: 8px;

    p {
        color: $gray-600;
        @include font-regular(14px, 18px);
    }

    .progressbar__wrapper {
        position: relative;

        display: flex;
        align-items: center;

        width: 100%;

        border-radius: 99px;

        overflow: hidden;

        .progressbar {
            @extend %player__progressbar;
        }

        .progress {
            position: absolute;

            height: 12px;

            border-radius: 99px;

            background-color: $primary-700;

            pointer-events: none;
            cursor: pointer;
        }
    }
}
