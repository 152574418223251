@import 'src/assets/styles/colors';

html,
* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
}

h1 {
    font-family: Montserrat, sans-serif;
    font-size: 22px;
    font-weight: 600;
    line-height: 26px;
    letter-spacing: 0;
    color: $gray-800;
}

h2 {
    font-family: Montserrat, sans-serif;
    font-size: 18px;
    font-weight: 600;
    line-height: 22px;
    letter-spacing: 0;
}

a {
    color: inherit;
    text-decoration: none;
}

button {
    padding: 0;
    border: none;
    font: inherit;
    color: inherit;
    background-color: transparent;
}
