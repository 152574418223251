@import 'src/assets/styles/colors';

.icon__wrapper {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    width: 80px;
    height: 80px;

    transition: opacity 0.7s ease;

    &:before {
        content: '';
        position: absolute;

        width: 100%;
        height: 100%;

        border-radius: 100%;

        background-color: $white;
        opacity: 0;

        transition: opacity 0.7s ease;
    }

    &:hover {
        &:before {
            opacity: 0.3;
        }
    }
}
