@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/breakpoints';

.container {
    display: flex;
    flex-direction: column;

    width: 100%;
    height: 100%;
    padding: 32px;
    gap: 30px;

    background-color: $gray-100;

    @media all and (max-width: $breakpoint-sm) {
        padding: 16px;
    }

    .content {
        display: flex;
        flex-direction: row;

        margin: 0 84px;
        height: calc(100% - 56px);
        background-color: $white;

        border: 1px solid $gray-200;
        border-radius: 16px;

        @media all and (max-width: $breakpoint-md) {
            margin: 0;
        }

        .candidates__list_wrapper {
            display: flex;
            flex-direction: column;

            padding-top: 28px;
            gap: 16px;

            border-right: 1px solid $gray-200;

            @media all and (max-width: $breakpoint-sm) {
                width: 100%;
                max-width: 100%;
                padding-top: 16px;

                border-right: none;
            }

            .title {
                display: flex;
                flex-direction: row;

                padding-left: 28px;
                gap: 8px;

                @media all and (max-width: $breakpoint-sm) {
                    padding-left: 16px;
                }

                .candidates__amount {
                    display: flex;
                    align-items: center;

                    padding: 0 6px;

                    border-radius: 100px;

                    background-color: $yellow-200;

                    color: $yellow-800;

                    @include font-medium(14px, 18px);
                }
            }

            .candidates__list {
                display: flex;
                flex-direction: column;

                width: 100%;
                padding: 4px 28px 28px 28px;
                gap: 12px;

                overflow-y: scroll;

                @media all and (max-width: $breakpoint-sm) {
                    padding: 4px 16px 16px 16px;
                }
            }
        }
    }
}
