@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/breakpoints';

.form {
    display: flex;
    flex-direction: column;
    align-items: center;

    width: 360px;
    gap: 44px;

    @media all and (max-width: $breakpoint-sm) {
        justify-content: space-between;

        width: 100%;
        height: 100%;
        gap: 0;
    }

    .inputs {
        display: flex;
        flex-direction: column;

        width: 100%;
        gap: 24px;

        @media all and (max-width: $breakpoint-sm) {
            padding: 0 16px;
        }
    }

    .button__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;

        width: 100%;
        max-width: 360px;
        gap: 6px;

        @media all and (max-width: $breakpoint-sm) {
            padding: 8px 16px;
            border-top: 1px solid $gray-200;
        }

        .error__text {
            color: $error-500;
            text-align: center;

            @include font-regular(12px, 16px);
        }
    }
}
