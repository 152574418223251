//Common

$primary-700: #1d958f;
$primary-800: #156b66;
$primary-900: #0c403d;
$white: #ffffff;
$black: #000000;

//Typography

$gray-400: #98a2b3;
$gray-500: #667085;
$gray-600: #475467;
$gray-700: #344054;
$gray-800: #1d2939;
$error-500: #f04438;
$error-600: #d92d20;
$error-700: #b42318;
$yellow-800: #705e0f;

// Borders

$gray-200: #e4e7ec;
$gray-300: #d0d5dd;
$yellow-500: #e4c235;

//Background

$primary-50: #f3fcfb;
$gray-100: #f2f4f7;
$yellow-100: #fcf8e9;
$yellow-200: #f6ebbc;
$yellow-300: #f0dd8f;
$background-gradient: linear-gradient(160.62deg, #0d1a63 2.62%, #384272 34.11%);

// Buttons

$error-100: #fee4e2;
$error-200: #fecdca;
$error-300: #fda29b;

$primary-100: #eafbfa;
$primary-200: #bff3f0;
$primary-300: #94eae6;

$error-800: #912018;
$error-900: #7a271a;
