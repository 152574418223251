@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/breakpoints';

.container {
    display: grid;
    grid-template-columns: 1fr 1fr;

    width: 100%;
    height: 100vh;

    background: $background-gradient;

    @media all and (max-width: $breakpoint-sm) {
        grid-template-rows: 56px 1fr;
        grid-template-columns: 1fr;
    }

    .logo__wrapper {
        display: flex;
        justify-content: center;
        align-items: center;

        @media all and (max-width: $breakpoint-sm) {
            justify-content: flex-start;

            padding: 12px 16px;
        }

        .logo__main {
            @media all and (max-width: $breakpoint-sm) {
                display: none;
            }
        }

        .logo__mobile {
            display: none;

            @media all and (max-width: $breakpoint-sm) {
                display: block;
            }
        }
    }

    .form__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        gap: 44px;

        background-color: $white;

        @media all and (max-width: $breakpoint-sm) {
            border-radius: 24px 24px 0 0;
        }

        .title {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 6px;

            @media all and (max-width: $breakpoint-sm) {
                padding-top: 24px;
            }

            p {
                color: $gray-500;
                @include font-regular(16px, 20px);
            }
        }
    }
}
