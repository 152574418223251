@import 'src/assets/styles/colors';

.container {
    display: flex;
    flex-direction: column;

    width: 100%;
    gap: 12px;

    .title__block,
    .main__block {
        width: 152px;
        height: 34px;

        border: 1px solid $gray-100;
        border-radius: 16px;

        background: linear-gradient(90deg, $gray-100, $white 50%, $gray-100);
        background-size: 200% 100%;

        animation-name: skeleton-gradient;
        animation-duration: 1.8s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    .main__block {
        min-width: 450px;
        width: 100%;
        height: 97px;
    }

    @keyframes skeleton-gradient {
        to {
            background-position-x: -200%;
        }
    }
}
