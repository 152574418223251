@import 'src/assets/styles/colors';

.play__button {
    position: absolute;
    top: 0;
    left: 0;

    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;

    border-radius: 24px;

    opacity: 0;

    transition: opacity 1s ease;

    cursor: pointer;

    z-index: 2;

    &:hover {
        opacity: 1;
        transition: opacity 1s ease;
    }
}

.play__button_paused {
    opacity: 1;
}
