@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/breakpoints';

.container {
    position: relative;

    display: flex;
    flex-direction: row;

    width: 100%;
    height: 100vh;

    background: $background-gradient;

    @media all and (max-width: $breakpoint-sm) {
        flex-direction: column;
    }

    .blur,
    .clear {
        position: absolute;

        width: 100%;
        height: 100%;

        transition: all 0.5s ease;
    }

    .blur {
        background: rgba(0, 0, 0, 0.2);
        backdrop-filter: blur(12px);

        button {
            position: absolute;

            top: 20px;
            right: 20px;

            cursor: pointer;
        }
    }

    .clear {
        visibility: hidden;
    }

    .header {
        display: none;

        @media all and (max-width: $breakpoint-sm) {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            padding: 12px 16px;
        }

        button {
            position: absolute;

            top: 20px;
            right: 20px;

            cursor: pointer;
        }
    }

    .sidebar__wrapper_shown,
    .sidebar__wrapper_hidden {
        height: 100%;

        transition: transform 0.5s ease;

        @media all and (max-width: $breakpoint-sm) {
            position: absolute;

            transition: transform 0.5s ease;
        }
    }

    .sidebar__wrapper_shown {
        @media all and (max-width: $breakpoint-sm) {
            transform: translateX(0);
        }
    }

    .sidebar__wrapper_hidden {
        @media all and (max-width: $breakpoint-sm) {
            transform: translateX(-100%);
        }
    }

    .content__wrapper {
        width: 100%;
        height: 100%;

        background-color: $white;

        @media all and (max-width: $breakpoint-sm) {
            height: calc(100% - 56px);
            border-radius: 24px 24px 0 0;
        }
    }
}
