@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';

.link {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    position: relative;

    width: 100%;
    height: 48px;
    padding: 0 16px;
    gap: 8px;
    border-radius: 12px;

    background-color: inherit;

    transition: all 0.5s ease;

    &:hover {
        background: #ffffff1a;
    }

    svg {
        min-width: 24px;
        fill: $white;
    }

    p {
        color: $white;

        @include font-medium(16px, 20px);

        opacity: 1;
        transition: opacity 1.3s ease;
    }

    &__active {
        background-color: $white;

        &:hover {
            background: $primary-50;
        }

        &:before {
            content: '';

            position: absolute;
            left: -12px;

            width: 4px;
            height: 48px;
            border-radius: 0 99px 99px 0;

            background: $primary-700;
        }

        svg {
            fill: $primary-700;
        }

        p {
            color: $primary-800;
        }
    }
}

.link__collapsed {
    @extend .link;

    p {
        opacity: 0;
        transition: opacity 0.3s ease;

    }
}
