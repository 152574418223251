@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';

.primary,
.secondary {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 360px;
    height: 48px;
    padding: 10px;
    gap: 16px;

    border-radius: 12px;
    transition: all 0.3s ease;
    cursor: pointer;

    @include font-semibold(14px, 18px);
}

.primary {
    box-shadow: 0 1px 2px 0 #1018280d;
    background: linear-gradient(91.3deg, #218aaa 20.12%, #1ba19e 100%);
    color: $white;

    &:hover {
        opacity: 0.9;
    }

    &:active {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
            linear-gradient(91.3deg, #218aaa 20.12%, #1ba19e 100%);
        opacity: 1;
    }
}

.secondary {
    background-color: $white;
    color: $error-600;

    &:hover {
        background-color: $error-100;
    }

    &:active {
        background-color: $error-200;
        color: $error-700;
    }
}
