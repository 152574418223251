@import 'src/assets/styles/colors';

%preloader__animation {
    path {
        animation-duration: 1.2s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
    }

    path:nth-child(1) {
        animation-delay: -0.7s;
    }

    path:nth-child(3) {
        animation-delay: -0.8s;
    }

    path:nth-child(4) {
        animation-delay: -0.1s;
    }

    path:nth-child(5) {
        animation-delay: -0.9s;
    }

    path:nth-child(6) {
        animation-delay: -0.2s;
    }

    path:nth-child(7) {
        animation-delay: -1s;
    }

    path:nth-child(8) {
        animation-delay: -0.4s;
    }

    path:nth-child(9) {
        animation-delay: -1.1s;
    }

    path:nth-child(10) {
        animation-delay: -0.5s;
    }

    path:nth-child(11) {
        animation-delay: -1.2s;
    }

    path:nth-child(12) {
        animation-delay: -0.6s;
    }
}

.preloader__primary {
    path {
        animation-name: loading-spin-primary;
    }

    @extend %preloader__animation;

    @keyframes loading-spin-primary {
        0% {
            fill: $primary-300;
        }
        100% {
            fill: $primary-900;
        }
    }
}

.preloader__secondary {
    path {
        animation-name: loading-spin-secondary;
    }

    @extend %preloader__animation;

    @keyframes loading-spin-secondary {
        0% {
            fill: $error-300;
        }
        100% {
            fill: $error-900;
        }
    }
}
