@import 'src/assets/styles/colors';

.accept,
.reject {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 100%;
    max-width: 190px;
    height: 60px;
    padding: 18px;

    border-radius: 999px;
    box-shadow: 0 4px 12px 0 #4e4f4f33;
    transition: all 0.3s ease;
    cursor: pointer;
}

.accept {
    background-color: $primary-100;

    &:hover {
        background-color: $primary-200;
    }
    &:active {
        background-color: $primary-300;
    }
}

.reject {
    background-color: $error-100;

    &:hover {
        background-color: $error-200;
    }
    &:active {
        background-color: $error-300;
    }
}
