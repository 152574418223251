@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 6px;

    label {
        position: relative;

        display: flex;
        flex-direction: column;
        gap: 6px;

        width: 100%;

        .label__text {
            color: $gray-700;
            @include font-medium(14px, 18px);
        }

        .text__field,
        .text__field_error {
            width: 100%;
            height: 44px;
            padding: 10px 14px;

            border-radius: 8px;
            box-shadow: 0 1px 2px 0 #1018280d;

            @include font-medium(14px, 18px);

            &:focus {
                outline: none;
                border: 1px solid $yellow-500;
                box-shadow: 0 0 0 4px rgba(246, 235, 188, 0.8);
            }

            &::placeholder {
                color: $gray-400;
            }
        }

        .text__field {
            border: 1px solid $gray-300;
        }

        .text__field_error {
            border: 1px solid $error-300;
        }

        .password__icon {
            position: absolute;
            right: 10px;
            bottom: 10px;

            cursor: pointer;
        }
    }

    .error__text {
        color: $error-500;

        @include font-regular(12px, 16px);
    }
}
