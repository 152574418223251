@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/ui';
@import 'src/assets/styles/breakpoints';

.sidebar__full,
.sidebar__mini {
    position: relative;

    height: 100%;

    background: $background-gradient;

    transition: width 0.5s ease, transform 0.5s ease;

    .sidebar__toggle {
        display: flex;
        justify-content: center;
        align-items: center;

        position: absolute;
        top: 11px;
        right: -22px;

        width: 44px;
        height: 44px;
        border-radius: 100%;

        background-color: $white;
        box-shadow: 0 4px 15px 0 #49494940;

        transition: transform 0.5s ease;
        cursor: pointer;

        @media all and (max-width: $breakpoint-sm) {
            display: none;
        }
    }

    .sidebar__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;

        width: 100%;
        height: 100%;

        overflow: hidden;

        .sidebar__header {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            width: 100%;
            gap: 32px;

            .logo {
                height: 48px;
                padding: 16px 16px 0 16px;
            }
        }

        .sidebar__footer {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            padding: 0 16px 16px 16px;
            gap: 20px;

            .user__info {
                display: grid;
                grid-template-columns: auto 1fr;
                grid-template-areas: 'avatar name' 'avatar email';
                grid-gap: 6px;

                .user__avatar {
                    grid-area: avatar;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    width: 48px;
                    height: 48px;
                    margin-right: 6px;
                    border-radius: 100px;

                    background-color: $yellow-200;
                }

                .user__name {
                    grid-area: name;

                    color: $white;

                    @include font-semibold(14px, 17px);
                }

                .user__email {
                    grid-area: email;

                    @extend %email__typography;
                }
            }

            .logout__button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;

                padding: 8px 18px;
                gap: 10px;

                color: $white;

                cursor: pointer;

                @include font-medium(14px, 14px);
            }
        }
    }
}

.sidebar__full {
    width: 256px;
}

.sidebar__mini {
    align-items: center;

    width: 80px;

    .sidebar__toggle {
        transform: rotateY(180deg);
        transition: all 0.5s ease;
    }

    .sidebar__content {
        .sidebar__footer {
            .user__info {
                width: 100%;

                .user__avatar {
                    margin: 0;
                }

                .user__name,
                .user__email {
                    display: none;
                }
            }

            .logout__button {
                width: 100%;
                padding: 8px;

                p {
                    display: none;
                }
            }
        }
    }
}
