@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/breakpoints';

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    width: 100%;
    height: 100%;
    gap: 8px;

    @media all and (max-width: $breakpoint-sm) {
        display: none;
    }

    .icon {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 60px;
        height: 60px;

        border: 8px solid $gray-200;
        border-radius: 100%;

        background-color: $gray-300;
    }

    p {
        color: $gray-500;
        text-align: center;

        @include font-regular(16px, 20px);
    }
}
