@import 'src/assets/styles/colors';
@import 'src/assets/styles/fonts';
@import 'src/assets/styles/breakpoints';

// Admin pages

%list__item_container {
    display: flex;
    flex-direction: row;

    min-width: 350px;
    padding: 12px;
    gap: 6px;

    background-color: $white;

    border: 1px solid $gray-200;
    border-radius: 16px;

    cursor: pointer;

    transition: background-color 0.3s ease;

    &:hover {
        background-color: $yellow-100;
    }

    @media all and (max-width: $breakpoint-md) {
        min-width: 0;
    }
}

%email__typography {
    padding: 5px 10px;

    color: $yellow-800;
    border-radius: 16px;
    background-color: $yellow-200;

    @include font-medium(12px, 14px);
}

// Video player

%player__progressbar {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;

    height: 12px;
    width: 100%;

    background-color: $gray-200;

    border-radius: 99px;

    cursor: pointer;

    &::-webkit-slider-thumb {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;

        width: 4px;
        height: 4px;

        background-color: $gray-200;

        opacity: 0;
    }
}
